import { useState } from "react";
import { ButtonMenu } from "src/assets/svg/ButtonMenu";
import { CaptowLogo } from "src/assets/svg/CaptownLogo";
import { LogoMenu } from "src/assets/svg/LogoMenu";
import { MenuMobile } from "../MenuMobile";
import { Nav } from "../Nav";
import "./index.css";

const Header = () => {
  const [show, set_show] = useState<boolean>(false);

  const show_hide = () => {
    set_show(!show);
  };

  return (
    <div className="header content" id="header">
      <div className="container-full">
        <CaptowLogo />
        <LogoMenu />

        <div className="header-nav">
          <Nav />
          <button>Entrar</button>
        </div>

        <div className="header-mobile">
          <MenuMobile show={show} close_menu={() => show_hide()} />
        </div>

        <ButtonMenu showHidden={() => show_hide()} />
      </div>
    </div>
  );
};

export default Header;
