import { useState } from "react";
import { ArrowDown } from "src/assets/svg/ArrowDown";
import { Heart } from "src/assets/svg/Heart";
import { CardAbout } from "../cards/about";
import "./index.css";

export const About = () => {
  const infos: string[] = [
    "Que trabalhar é muito mais do que estar presente em um escritório físico das 9h às 18h, cinco dias por semana, mas poder produzir o seu melhor trabalho de qualquer lugar.",
    "Que igualdade de condições inclui o fim das barreiras geográficas e da obrigatoriedade de deslocamentos diários de 2h para bater crachá. O meio ambiente e a nossa saúde mental agradecem!",
    "No crescimento profissional meritocrático e que líderes e colaboradores são capazes de implementar o melhor formato de trabalho para sua realidade individual, contribuindo para o crescimento das empresas e profissional de cada colaborador. Estamos todos no mesmo barco!",
    "Que intervalos durante o trabalho não precisam estar restritos ao bate-papo na copa, mas pode incluir uma caminhada com o pet, regar uma planta, jogar uma partida de pingue-pongue, ou passar um tempo com os filhos. Por falar em filhos, acreditamos que não existe melhor contribuição para próxima geração do que uma família presente. Ter alguns dias trabalhando de casa ou estar mais próximo faz toda diferença!",
    "Que cada um tem uma missão única e especial nesse mundo e que além do trabalho existe uma infinidade de experiências e objetivos para serem vividos e conquistados.",
    "Por fim, acreditamos que no fundo o que todos estamos em busca é de mais espaço. Espaço para sonhar, viver, crescer e entregarmos ao mundo todo nosso potencial. ",
  ];

  const [show, set_show] = useState<boolean>(false);

  const list = infos.map((info: string, i: number) => {
    return <CardAbout content={info} key={i} />;
  });

  return (
    <div className="about" id="about">
      <div className="container-heart">
        <Heart />
      </div>
      <div className={`container-full  ${show ? "show_hidden" : " "}`}>
        <h1 className="title-about">No que acreditamos</h1>
        <div className="cards-about">
          <div className="cards-body">
            <div className="cards">{list}</div>
            <h2>
              Seja bem vindo à comunidade captown, juntos podemos criar uma
              melhor dinâmica para viver produzindo valor!
            </h2>
          </div>
        </div>
      </div>
      <button
        className={`${show ? "show_hidden_button" : " "}`}
        onClick={() => set_show(!show)}
      >
        {show ? "Ver menos" : "Ver mais"}
        <ArrowDown />
      </button>
    </div>
  );
};
