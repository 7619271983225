import "./index.css";

import { BenefitType } from "src/types/benefit";
import { CardBenefit } from "../cards/benefit";
import { LineBetween } from "src/assets/svg/LineBetween";

export const Benefit = () => {
  const list: BenefitType[] = [
    {
      title: "Chegue no escritório em poucos clicks",
      body: "Esqueça a obrigatoriedade de pegar horas de trânsito para reunir-se ao seu time de trabalho. Replicamos a interação física no mundo virtual.",
    },
    {
      title: "Comunicação única e inclusiva",
      body: "Acabe com a segregação e dificuldade de comunicação entre colabores que possuem diferentes modelos de trabalho (presencial, remoto e híbrido). Todos os colabores estarão sempre presentes na Captown.",
    },
    {
      title: "A maioria das conversas não necessita virar uma reunião",
      body: "Encontre o seu colega de equipe para fazer uma pergunta rápida ou apenas dizer “oi” andando facilmente pelo seu escritório na Captown.",
    },
    {
      title: "Não possui escritório físico? Sem problema",
      body: "Com a Captown você terá seu escritório exclusivo com mesas para cada um dos seus colaboradores. Fique à vontade para personaliza-las como desejar",
    },
    {
      title: "Reuniões internas e externas",
      body: "Esqueça o processo de enviar convites através de aplicativos externos. Ande para uma sala de reunião do seu escritório na Captown e realize quantas reuniões forem necessárias, sem limitação de tempo, afinal o escritório é seu.",
    },
    {
      title: "Construindo a cultura da sua empresa",
      body: "A cultura da sua empresa é criada no relacionamento de trabalho, conversas privadas, bate-papos descontraído e happy hours. Faça tudo isso e muito maisna Captown.",
    },
  ];

  const options: any[] = list.map((item: BenefitType, i: number) => {
    return <CardBenefit benefit={item} key={i} />;
  });

  return (
    <div className="benefit" id="benefit">
      <div className="container-line">
        <LineBetween />
      </div>
      <div className="container-full">
        <div className="post-it-benefit">Benefícios</div>
        <div className="cards-benefit">{options}</div>
        <button>Comece já!</button>
      </div>
    </div>
  );
};
