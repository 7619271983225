import { Price } from "src/types/price";
import { Card } from "../cards/pricing";
import "./index.css";

export const Pricing = () => {
  const options: Price[] = [
    {
      title: "Inicial",
      options: [
        { label: "Lorem ipsum", check: true },
        { label: "Lorem ipsum", check: true },
        { label: "Lorem ipsum", check: false },
        { label: "Lorem ipsum", check: false },
        { label: "Lorem ipsum", check: false },
      ],
      value: "00,00",
    },
    {
      title: "Intermediário",
      options: [
        { label: "Lorem ipsum", check: true },
        { label: "Lorem ipsum", check: true },
        { label: "Lorem ipsum", check: true },
        { label: "Lorem ipsum", check: true },
        { label: "Lorem ipsum", check: false },
      ],
      value: "00,00",
    },
    {
      title: "Premium",
      options: [
        { label: "Lorem ipsum", check: true },
        { label: "Lorem ipsum", check: true },
        { label: "Lorem ipsum", check: true },
        { label: "Lorem ipsum", check: true },
        { label: "Lorem ipsum", check: true },
      ],
      value: "00,00",
    },
  ];

  const options_card: any[] = options.map((price: Price, i: number) => {
    return <Card price={price} key={i} />;
  });

  return (
    <div className="pricing" id="pricing">
      <div className="container-full">
        <h1 className="title-pricing">Captown na sua empresa</h1>
        <p className="subtitle-pricing">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
          purus sit amet luctus venenatis, lectus magna fringilla urna,
          porttitor
        </p>
        <div className="info-price">
          <div className="card-price">{options_card}</div>
          <button>Entrar em contato</button>
        </div>
      </div>
    </div>
  );
};
