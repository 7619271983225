export const ArrowDown = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="arrow-down"
    >
      <path
        d="M0.292771 10.5357C-0.0977531 10.1452 -0.0977531 9.51201 0.292771 9.12148C0.683295 8.73096 1.31646 8.73096 1.70698 9.12148L6.99997 14.4145L6.99997 1.82859C6.99997 1.27631 7.44769 0.82859 7.99997 0.82859C8.55226 0.82859 8.99997 1.27631 8.99997 1.82859L8.99997 14.4144L14.2929 9.12148C14.6835 8.73096 15.3166 8.73096 15.7071 9.12148C16.0977 9.51201 16.0977 10.1452 15.7071 10.5357L10.1213 16.1216C8.94971 17.2931 7.05021 17.2931 5.87864 16.1216L0.292771 10.5357Z"
        fill="#5438DC"
      />
    </svg>
  );
};
