import "./index.css";

import logo from "../../assets/img/white-logo.png";
import facebook from "../../assets/img/facebook.png";
import instagram from "../../assets/img/instagram.png";
import linkedin from "../../assets/img/linkedin.png";
import youtube from "../../assets/img/youtube.png";

export const Footer = () => {
  const icons: any[] = [
    { img: facebook, url: "" },
    { img: instagram, url: "" },
    { img: linkedin, url: "" },
    { img: youtube, url: "" },
  ];

  const list = icons.map((icon, i) => {
    return (
      <a href={icon.url} key={i}>
        {" "}
        <img src={icon.img} alt="" />
      </a>
    );
  });

  return (
    <div className="footer" id="footer">
      <div className="container-full">
        <div className="links">
          <img src={logo} alt="" className="logo" />
          <div className="list">{list}</div>
        </div>
        <div className="external-links">
          <div className="info-links">
            <a href="/" target="_blank" rel="noreferrer">
              Politica de Privacidade
            </a>
            <a href="/" target="_blank" rel="noreferrer">
              Termos de Uso
            </a>
          </div>
          <p>2022 © Captown. Todos os direitos reservados.</p>
        </div>
      </div>
    </div>
  );
};
