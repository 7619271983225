import "./index.css";

import { Link } from "react-scroll";
import { ArrowLink } from "src/assets/svg/ArrowLink";

import logo from "../../assets/img/white-logo.png";
import close from "../../assets/img/close.svg";
import facebook from "../../assets/img/facebook.png";
import instagram from "../../assets/img/instagram.png";
import linkedin from "../../assets/img/linkedin.png";
import youtube from "../../assets/img/youtube.png";

interface PropsMenuMobile {
  show: boolean;
  close_menu: Function;
}

export const MenuMobile = ({ show, close_menu }: PropsMenuMobile) => {
  const links = [
    { label: "No que acreditamos", link: "about" },
    { label: "Features", link: "pricing" },
    { label: "Benefícios", link: "feature" },
    { label: "Blog", link: "" },
  ];

  const icons: any[] = [
    { img: facebook, url: "" },
    { img: instagram, url: "" },
    { img: linkedin, url: "" },
    { img: youtube, url: "" },
  ];

  const list = icons.map((icon, i) => {
    return (
      <a href={icon.url} key={i}>
        {" "}
        <img src={icon.img} alt="" />
      </a>
    );
  });

  const list_links = links.map((item, i) => {
    return (
      <div className="link" key={i}>
        {item.label === "Blog" ? (
          <a
            className="flex items-center"
            href={item.link}
            target="_blank"
            rel="noreferrer"
          >
            {item.label}
            <ArrowLink class_svg="svg-link ml-2" />
          </a>
        ) : (
          <Link smooth={true} duration={500} to={item.link}>
            {item.label}
          </Link>
        )}
      </div>
    );
  });

  return (
    <div className={`menu-mobile ${show ? " " : "hidden"}`}>
      <div className="body-mobile">
        <div className="header-menu-mobile">
          <img src={logo} alt="" />
          <img src={close} alt="" onClick={() => close_menu()} />
        </div>
        <div className="list-menu-mobile">
          <div className="list-nav">{list_links}</div>
          <button>Entrar</button>
          <div className="list-link-mobile">{list}</div>
        </div>
      </div>
    </div>
  );
};
