import { About } from "src/components/About";
import { Feature } from "src/components/Feature";
import { Connect } from "src/components/Connect";
import { Footer } from "src/components/Footer";
import Header from "src/components/Header";
import Hero from "src/components/Hero";
import { Pricing } from "src/components/Pricing";
import { Benefit } from "src/components/Benefit";

const Main = () => {
  return (
    <div className="main">
      <Header />
      <Hero />
      <Connect />
      <Feature />
      <Benefit />
      <About />

      <Pricing />
      <Footer />
    </div>
  );
};

export default Main;
