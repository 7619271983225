import "./index.css";

import { BenefitType } from "src/types/benefit";

interface PropsBenefit {
  benefit: BenefitType;
}

export const CardBenefit = ({ benefit }: PropsBenefit) => {
  return (
    <div className="card-benefit">
      <h1>{benefit.title}</h1>
      <p>{benefit.body}</p>
    </div>
  );
};
