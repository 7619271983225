interface PropsArrowLink {
  class_svg: string;
}

export const ArrowLink = ({ class_svg }: PropsArrowLink) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={class_svg}
    >
      <path
        d="M13.5958 11.2178C13.5958 11.7701 13.1481 12.2178 12.5958 12.2178C12.0435 12.2178 11.5958 11.7701 11.5958 11.2178L11.5958 3.73237L2.69623 12.6319C2.30571 13.0224 1.67254 13.0224 1.28202 12.6319C0.891493 12.2414 0.891493 11.6082 1.28202 11.2177L10.1816 2.31817L2.69618 2.31817C2.1439 2.31817 1.69618 1.87045 1.69618 1.31817C1.69618 0.765882 2.1439 0.318167 2.69618 0.318167L10.5958 0.318168C12.2526 0.318168 13.5958 1.66132 13.5958 3.31817L13.5958 11.2178Z"
        fill="#5438DC"
      />
    </svg>
  );
};
