import "./App.css";
import Main from "./pages/Main";

const App = () => {
  return (
    <div id="app">
      <Main />
    </div>
  );
};

export default App;
