import "./index.css";

export const Video = () => {
  return (
    <div className="video">
      <iframe
        src="https://www.youtube.com/embed/WU0gvPcc3jQ?autoplay=1&mute=1"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};
