export const LineBetween = () => {
  return (
    <svg
      width="40"
      height="205"
      viewBox="0 0 40 205"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="line-between"
    >
      <path d="M40 0V204.957H0V0L40 0Z" fill="url(#paint0_linear_427_4716)" />
      <defs>
        <linearGradient
          id="paint0_linear_427_4716"
          x1="-1.75295e+13"
          y1="-241.324"
          x2="-1.75295e+13"
          y2="163.688"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.192291" stopColor="#0AEA5A" />
          <stop offset="0.829867" stopColor="#A3FF24" />
        </linearGradient>
      </defs>
    </svg>
  );
};
