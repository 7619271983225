export const Heart = () => {
  return (
    <svg
      width="416"
      height="414"
      viewBox="0 0 416 414"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="heart"
    >
      <path
        d="M125.029 413.482L76.5 365L302.793 172.866C310.055 165.611 314.071 155.966 314.071 145.723C314.071 135.481 310.055 125.835 302.793 118.58C287.756 103.558 263.406 103.558 248.369 118.58L183.436 183.45C141.486 225.36 73.3059 225.36 31.3558 183.45C11.1924 163.05 0 136.078 0 107.399H68.6068C68.6068 117.812 72.6224 127.543 79.9701 134.883C95.1781 150.076 119.87 150.076 134.992 134.883L199.925 70.0129C241.704 28.274 309.628 28.274 351.322 70.0129C371.57 90.2422 382.677 117.044 382.677 145.638C382.677 174.232 371.57 201.034 351.322 221.263L125.029 413.482Z"
        fill="#0AEA5A"
      />
      <path
        d="M445.5 379C436.7 379 427.985 375.671 421.236 368.928L135.078 79.9141C119.87 64.7208 95.1781 64.7208 80.0555 79.9141C72.7079 87.2547 68.6923 96.9853 68.6923 107.399C68.6923 126.348 53.3134 141.626 34.4316 141.626C15.3789 141.626 0 126.348 0 107.399C0 78.7192 11.1924 51.7468 31.5267 31.4322C73.4768 -10.4774 141.656 -10.4774 183.607 31.4322L469.85 320.531C483.264 333.932 483.264 355.613 469.85 369.013C463.015 375.671 454.3 379 445.5 379Z"
        fill="url(#paint0_linear_329_836)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_329_836"
          x1="0"
          y1="206.74"
          x2="479.771"
          y2="206.74"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0712419" stopColor="#0AEA5A" />
          <stop offset="0.1195" stopColor="#1EED53" />
          <stop offset="0.2452" stopColor="#4DF342" />
          <stop offset="0.3649" stopColor="#73F835" />
          <stop offset="0.4757" stopColor="#8DFC2C" />
          <stop offset="0.5742" stopColor="#9DFE26" />
          <stop offset="0.6503" stopColor="#A3FF24" />
        </linearGradient>
      </defs>
    </svg>
  );
};
