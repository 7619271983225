export const Dots = () => {
  return (
    <svg
      width="510"
      height="608"
      viewBox="0 0 510 608"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.87386 19.7188C15.327 19.7188 19.7477 15.3046 19.7477 9.85942C19.7477 4.41421 15.327 0 9.87386 0C4.42068 0 0 4.41421 0 9.85942C0 15.3046 4.42068 19.7188 9.87386 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 19.7188C52.8344 19.7188 57.255 15.3046 57.255 9.85942C57.255 4.41421 52.8344 0 47.3812 0C41.928 0 37.5073 4.41421 37.5073 9.85942C37.5073 15.3046 41.928 19.7188 47.3812 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 19.7188C90.3417 19.7188 94.7624 15.3046 94.7624 9.85942C94.7624 4.41421 90.3417 0 84.8885 0C79.4353 0 75.0146 4.41421 75.0146 9.85942C75.0146 15.3046 79.4353 19.7188 84.8885 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 19.7188C127.849 19.7188 132.27 15.3046 132.27 9.85942C132.27 4.41421 127.849 0 122.396 0C116.943 0 112.522 4.41421 112.522 9.85942C112.522 15.3046 116.943 19.7188 122.396 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 19.7188C165.356 19.7188 169.777 15.3046 169.777 9.85942C169.777 4.41421 165.356 0 159.903 0C154.45 0 150.029 4.41421 150.029 9.85942C150.029 15.3046 154.45 19.7188 159.903 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 19.7188C202.87 19.7188 207.291 15.3046 207.291 9.85942C207.291 4.41421 202.87 0 197.417 0C191.964 0 187.543 4.41421 187.543 9.85942C187.543 15.3046 191.964 19.7188 197.417 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 19.7188C240.378 19.7188 244.799 15.3046 244.799 9.85942C244.799 4.41421 240.378 0 234.925 0C229.471 0 225.051 4.41421 225.051 9.85942C225.051 15.3046 229.471 19.7188 234.925 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 19.7188C277.885 19.7188 282.306 15.3046 282.306 9.85942C282.306 4.41421 277.885 0 272.432 0C266.979 0 262.558 4.41421 262.558 9.85942C262.558 15.3046 266.979 19.7188 272.432 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 19.7188C315.392 19.7188 319.813 15.3046 319.813 9.85942C319.813 4.41421 315.392 0 309.939 0C304.486 0 300.065 4.41421 300.065 9.85942C300.065 15.3046 304.486 19.7188 309.939 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 19.7188C352.899 19.7188 357.32 15.3046 357.32 9.85942C357.32 4.41421 352.899 0 347.446 0C341.993 0 337.572 4.41421 337.572 9.85942C337.572 15.3046 341.993 19.7188 347.446 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 19.7188C390.407 19.7188 394.828 15.3046 394.828 9.85942C394.828 4.41421 390.407 0 384.954 0C379.501 0 375.08 4.41421 375.08 9.85942C375.08 15.3046 379.501 19.7188 384.954 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 19.7188C427.921 19.7188 432.341 15.3046 432.341 9.85942C432.341 4.41421 427.921 0 422.468 0C417.014 0 412.594 4.41421 412.594 9.85942C412.594 15.3046 417.014 19.7188 422.468 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 19.7188C465.428 19.7188 469.849 15.3046 469.849 9.85942C469.849 4.41421 465.428 0 459.975 0C454.522 0 450.101 4.41421 450.101 9.85942C450.101 15.3046 454.522 19.7188 459.975 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 19.7188C502.935 19.7188 507.356 15.3046 507.356 9.85942C507.356 4.41421 502.935 0 497.482 0C492.029 0 487.608 4.41421 487.608 9.85942C487.608 15.3046 492.029 19.7188 497.482 19.7188Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 56.8849C15.327 56.8849 19.7477 52.4706 19.7477 47.0254C19.7477 41.5802 15.327 37.166 9.87386 37.166C4.42068 37.166 0 41.5802 0 47.0254C0 52.4706 4.42068 56.8849 9.87386 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 56.8849C52.8344 56.8849 57.255 52.4706 57.255 47.0254C57.255 41.5802 52.8344 37.166 47.3812 37.166C41.928 37.166 37.5073 41.5802 37.5073 47.0254C37.5073 52.4706 41.928 56.8849 47.3812 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 56.8849C90.3417 56.8849 94.7624 52.4706 94.7624 47.0254C94.7624 41.5802 90.3417 37.166 84.8885 37.166C79.4353 37.166 75.0146 41.5802 75.0146 47.0254C75.0146 52.4706 79.4353 56.8849 84.8885 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 56.8849C127.849 56.8849 132.27 52.4706 132.27 47.0254C132.27 41.5802 127.849 37.166 122.396 37.166C116.943 37.166 112.522 41.5802 112.522 47.0254C112.522 52.4706 116.943 56.8849 122.396 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 56.8849C165.356 56.8849 169.777 52.4706 169.777 47.0254C169.777 41.5802 165.356 37.166 159.903 37.166C154.45 37.166 150.029 41.5802 150.029 47.0254C150.029 52.4706 154.45 56.8849 159.903 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 56.8849C202.87 56.8849 207.291 52.4706 207.291 47.0254C207.291 41.5802 202.87 37.166 197.417 37.166C191.964 37.166 187.543 41.5802 187.543 47.0254C187.543 52.4706 191.964 56.8849 197.417 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 56.8849C240.378 56.8849 244.799 52.4706 244.799 47.0254C244.799 41.5802 240.378 37.166 234.925 37.166C229.471 37.166 225.051 41.5802 225.051 47.0254C225.051 52.4706 229.471 56.8849 234.925 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 56.8849C277.885 56.8849 282.306 52.4706 282.306 47.0254C282.306 41.5802 277.885 37.166 272.432 37.166C266.979 37.166 262.558 41.5802 262.558 47.0254C262.558 52.4706 266.979 56.8849 272.432 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 56.8849C315.392 56.8849 319.813 52.4706 319.813 47.0254C319.813 41.5802 315.392 37.166 309.939 37.166C304.486 37.166 300.065 41.5802 300.065 47.0254C300.065 52.4706 304.486 56.8849 309.939 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 56.8849C352.899 56.8849 357.32 52.4706 357.32 47.0254C357.32 41.5802 352.899 37.166 347.446 37.166C341.993 37.166 337.572 41.5802 337.572 47.0254C337.572 52.4706 341.993 56.8849 347.446 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 56.8849C390.407 56.8849 394.828 52.4706 394.828 47.0254C394.828 41.5802 390.407 37.166 384.954 37.166C379.501 37.166 375.08 41.5802 375.08 47.0254C375.08 52.4706 379.501 56.8849 384.954 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 56.8849C427.921 56.8849 432.341 52.4706 432.341 47.0254C432.341 41.5802 427.921 37.166 422.468 37.166C417.014 37.166 412.594 41.5802 412.594 47.0254C412.594 52.4706 417.014 56.8849 422.468 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 56.8849C465.428 56.8849 469.849 52.4706 469.849 47.0254C469.849 41.5802 465.428 37.166 459.975 37.166C454.522 37.166 450.101 41.5802 450.101 47.0254C450.101 52.4706 454.522 56.8849 459.975 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 56.8849C502.935 56.8849 507.356 52.4706 507.356 47.0254C507.356 41.5802 502.935 37.166 497.482 37.166C492.029 37.166 487.608 41.5802 487.608 47.0254C487.608 52.4706 492.029 56.8849 497.482 56.8849Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 94.0509C15.327 94.0509 19.7477 89.6367 19.7477 84.1914C19.7477 78.7462 15.327 74.332 9.87386 74.332C4.42068 74.332 0 78.7462 0 84.1914C0 89.6367 4.42068 94.0509 9.87386 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 94.0509C52.8344 94.0509 57.255 89.6367 57.255 84.1914C57.255 78.7462 52.8344 74.332 47.3812 74.332C41.928 74.332 37.5073 78.7462 37.5073 84.1914C37.5073 89.6367 41.928 94.0509 47.3812 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 94.0509C90.3417 94.0509 94.7624 89.6367 94.7624 84.1914C94.7624 78.7462 90.3417 74.332 84.8885 74.332C79.4353 74.332 75.0146 78.7462 75.0146 84.1914C75.0146 89.6367 79.4353 94.0509 84.8885 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 94.0509C127.849 94.0509 132.27 89.6367 132.27 84.1914C132.27 78.7462 127.849 74.332 122.396 74.332C116.943 74.332 112.522 78.7462 112.522 84.1914C112.522 89.6367 116.943 94.0509 122.396 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 94.0509C165.356 94.0509 169.777 89.6367 169.777 84.1914C169.777 78.7462 165.356 74.332 159.903 74.332C154.45 74.332 150.029 78.7462 150.029 84.1914C150.029 89.6367 154.45 94.0509 159.903 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 94.0509C202.87 94.0509 207.291 89.6367 207.291 84.1914C207.291 78.7462 202.87 74.332 197.417 74.332C191.964 74.332 187.543 78.7462 187.543 84.1914C187.543 89.6367 191.964 94.0509 197.417 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 94.0509C240.378 94.0509 244.799 89.6367 244.799 84.1914C244.799 78.7462 240.378 74.332 234.925 74.332C229.471 74.332 225.051 78.7462 225.051 84.1914C225.051 89.6367 229.471 94.0509 234.925 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 94.0509C277.885 94.0509 282.306 89.6367 282.306 84.1914C282.306 78.7462 277.885 74.332 272.432 74.332C266.979 74.332 262.558 78.7462 262.558 84.1914C262.558 89.6367 266.979 94.0509 272.432 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 94.0509C315.392 94.0509 319.813 89.6367 319.813 84.1914C319.813 78.7462 315.392 74.332 309.939 74.332C304.486 74.332 300.065 78.7462 300.065 84.1914C300.065 89.6367 304.486 94.0509 309.939 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 94.0509C352.899 94.0509 357.32 89.6367 357.32 84.1914C357.32 78.7462 352.899 74.332 347.446 74.332C341.993 74.332 337.572 78.7462 337.572 84.1914C337.572 89.6367 341.993 94.0509 347.446 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 94.0509C390.407 94.0509 394.828 89.6367 394.828 84.1914C394.828 78.7462 390.407 74.332 384.954 74.332C379.501 74.332 375.08 78.7462 375.08 84.1914C375.08 89.6367 379.501 94.0509 384.954 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 94.0509C427.921 94.0509 432.341 89.6367 432.341 84.1914C432.341 78.7462 427.921 74.332 422.468 74.332C417.014 74.332 412.594 78.7462 412.594 84.1914C412.594 89.6367 417.014 94.0509 422.468 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 94.0509C465.428 94.0509 469.849 89.6367 469.849 84.1914C469.849 78.7462 465.428 74.332 459.975 74.332C454.522 74.332 450.101 78.7462 450.101 84.1914C450.101 89.6367 454.522 94.0509 459.975 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 94.0509C502.935 94.0509 507.356 89.6367 507.356 84.1914C507.356 78.7462 502.935 74.332 497.482 74.332C492.029 74.332 487.608 78.7462 487.608 84.1914C487.608 89.6367 492.029 94.0509 497.482 94.0509Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 131.217C15.327 131.217 19.7477 126.803 19.7477 121.357C19.7477 115.912 15.327 111.498 9.87386 111.498C4.42068 111.498 0 115.912 0 121.357C0 126.803 4.42068 131.217 9.87386 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 131.217C52.8344 131.217 57.255 126.803 57.255 121.357C57.255 115.912 52.8344 111.498 47.3812 111.498C41.928 111.498 37.5073 115.912 37.5073 121.357C37.5073 126.803 41.928 131.217 47.3812 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 131.217C90.3417 131.217 94.7624 126.803 94.7624 121.357C94.7624 115.912 90.3417 111.498 84.8885 111.498C79.4353 111.498 75.0146 115.912 75.0146 121.357C75.0146 126.803 79.4353 131.217 84.8885 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 131.217C127.849 131.217 132.27 126.803 132.27 121.357C132.27 115.912 127.849 111.498 122.396 111.498C116.943 111.498 112.522 115.912 112.522 121.357C112.522 126.803 116.943 131.217 122.396 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 131.217C165.356 131.217 169.777 126.803 169.777 121.357C169.777 115.912 165.356 111.498 159.903 111.498C154.45 111.498 150.029 115.912 150.029 121.357C150.029 126.803 154.45 131.217 159.903 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 131.217C202.87 131.217 207.291 126.803 207.291 121.357C207.291 115.912 202.87 111.498 197.417 111.498C191.964 111.498 187.543 115.912 187.543 121.357C187.543 126.803 191.964 131.217 197.417 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 131.217C240.378 131.217 244.799 126.803 244.799 121.357C244.799 115.912 240.378 111.498 234.925 111.498C229.471 111.498 225.051 115.912 225.051 121.357C225.051 126.803 229.471 131.217 234.925 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 131.217C277.885 131.217 282.306 126.803 282.306 121.357C282.306 115.912 277.885 111.498 272.432 111.498C266.979 111.498 262.558 115.912 262.558 121.357C262.558 126.803 266.979 131.217 272.432 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 131.217C315.392 131.217 319.813 126.803 319.813 121.357C319.813 115.912 315.392 111.498 309.939 111.498C304.486 111.498 300.065 115.912 300.065 121.357C300.065 126.803 304.486 131.217 309.939 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 131.217C352.899 131.217 357.32 126.803 357.32 121.357C357.32 115.912 352.899 111.498 347.446 111.498C341.993 111.498 337.572 115.912 337.572 121.357C337.572 126.803 341.993 131.217 347.446 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 131.217C390.407 131.217 394.828 126.803 394.828 121.357C394.828 115.912 390.407 111.498 384.954 111.498C379.501 111.498 375.08 115.912 375.08 121.357C375.08 126.803 379.501 131.217 384.954 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 131.217C427.921 131.217 432.341 126.803 432.341 121.357C432.341 115.912 427.921 111.498 422.468 111.498C417.014 111.498 412.594 115.912 412.594 121.357C412.594 126.803 417.014 131.217 422.468 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 131.217C465.428 131.217 469.849 126.803 469.849 121.357C469.849 115.912 465.428 111.498 459.975 111.498C454.522 111.498 450.101 115.912 450.101 121.357C450.101 126.803 454.522 131.217 459.975 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 131.217C502.935 131.217 507.356 126.803 507.356 121.357C507.356 115.912 502.935 111.498 497.482 111.498C492.029 111.498 487.608 115.912 487.608 121.357C487.608 126.803 492.029 131.217 497.482 131.217Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 168.383C15.327 168.383 19.7477 163.969 19.7477 158.523C19.7477 153.078 15.327 148.664 9.87386 148.664C4.42068 148.664 0 153.078 0 158.523C0 163.969 4.42068 168.383 9.87386 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 168.383C52.8344 168.383 57.255 163.969 57.255 158.523C57.255 153.078 52.8344 148.664 47.3812 148.664C41.928 148.664 37.5073 153.078 37.5073 158.523C37.5073 163.969 41.928 168.383 47.3812 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 168.383C90.3417 168.383 94.7624 163.969 94.7624 158.523C94.7624 153.078 90.3417 148.664 84.8885 148.664C79.4353 148.664 75.0146 153.078 75.0146 158.523C75.0146 163.969 79.4353 168.383 84.8885 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 168.383C127.849 168.383 132.27 163.969 132.27 158.523C132.27 153.078 127.849 148.664 122.396 148.664C116.943 148.664 112.522 153.078 112.522 158.523C112.522 163.969 116.943 168.383 122.396 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 168.383C165.356 168.383 169.777 163.969 169.777 158.523C169.777 153.078 165.356 148.664 159.903 148.664C154.45 148.664 150.029 153.078 150.029 158.523C150.029 163.969 154.45 168.383 159.903 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 168.383C202.87 168.383 207.291 163.969 207.291 158.523C207.291 153.078 202.87 148.664 197.417 148.664C191.964 148.664 187.543 153.078 187.543 158.523C187.543 163.969 191.964 168.383 197.417 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 168.383C240.378 168.383 244.799 163.969 244.799 158.523C244.799 153.078 240.378 148.664 234.925 148.664C229.471 148.664 225.051 153.078 225.051 158.523C225.051 163.969 229.471 168.383 234.925 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 168.383C277.885 168.383 282.306 163.969 282.306 158.523C282.306 153.078 277.885 148.664 272.432 148.664C266.979 148.664 262.558 153.078 262.558 158.523C262.558 163.969 266.979 168.383 272.432 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 168.383C315.392 168.383 319.813 163.969 319.813 158.523C319.813 153.078 315.392 148.664 309.939 148.664C304.486 148.664 300.065 153.078 300.065 158.523C300.065 163.969 304.486 168.383 309.939 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 168.383C352.899 168.383 357.32 163.969 357.32 158.523C357.32 153.078 352.899 148.664 347.446 148.664C341.993 148.664 337.572 153.078 337.572 158.523C337.572 163.969 341.993 168.383 347.446 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 168.383C390.407 168.383 394.828 163.969 394.828 158.523C394.828 153.078 390.407 148.664 384.954 148.664C379.501 148.664 375.08 153.078 375.08 158.523C375.08 163.969 379.501 168.383 384.954 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 168.383C427.921 168.383 432.341 163.969 432.341 158.523C432.341 153.078 427.921 148.664 422.468 148.664C417.014 148.664 412.594 153.078 412.594 158.523C412.594 163.969 417.014 168.383 422.468 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 168.383C465.428 168.383 469.849 163.969 469.849 158.523C469.849 153.078 465.428 148.664 459.975 148.664C454.522 148.664 450.101 153.078 450.101 158.523C450.101 163.969 454.522 168.383 459.975 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 168.383C502.935 168.383 507.356 163.969 507.356 158.523C507.356 153.078 502.935 148.664 497.482 148.664C492.029 148.664 487.608 153.078 487.608 158.523C487.608 163.969 492.029 168.383 497.482 168.383Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 205.556C15.327 205.556 19.7477 201.142 19.7477 195.696C19.7477 190.251 15.327 185.837 9.87386 185.837C4.42068 185.837 0 190.251 0 195.696C0 201.142 4.42068 205.556 9.87386 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 205.556C52.8344 205.556 57.255 201.142 57.255 195.696C57.255 190.251 52.8344 185.837 47.3812 185.837C41.928 185.837 37.5073 190.251 37.5073 195.696C37.5073 201.142 41.928 205.556 47.3812 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 205.556C90.3417 205.556 94.7624 201.142 94.7624 195.696C94.7624 190.251 90.3417 185.837 84.8885 185.837C79.4353 185.837 75.0146 190.251 75.0146 195.696C75.0146 201.142 79.4353 205.556 84.8885 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 205.556C127.849 205.556 132.27 201.142 132.27 195.696C132.27 190.251 127.849 185.837 122.396 185.837C116.943 185.837 112.522 190.251 112.522 195.696C112.522 201.142 116.943 205.556 122.396 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 205.556C165.356 205.556 169.777 201.142 169.777 195.696C169.777 190.251 165.356 185.837 159.903 185.837C154.45 185.837 150.029 190.251 150.029 195.696C150.029 201.142 154.45 205.556 159.903 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 205.556C202.87 205.556 207.291 201.142 207.291 195.696C207.291 190.251 202.87 185.837 197.417 185.837C191.964 185.837 187.543 190.251 187.543 195.696C187.543 201.142 191.964 205.556 197.417 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 205.556C240.378 205.556 244.799 201.142 244.799 195.696C244.799 190.251 240.378 185.837 234.925 185.837C229.471 185.837 225.051 190.251 225.051 195.696C225.051 201.142 229.471 205.556 234.925 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 205.556C277.885 205.556 282.306 201.142 282.306 195.696C282.306 190.251 277.885 185.837 272.432 185.837C266.979 185.837 262.558 190.251 262.558 195.696C262.558 201.142 266.979 205.556 272.432 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 205.556C315.392 205.556 319.813 201.142 319.813 195.696C319.813 190.251 315.392 185.837 309.939 185.837C304.486 185.837 300.065 190.251 300.065 195.696C300.065 201.142 304.486 205.556 309.939 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 205.556C352.899 205.556 357.32 201.142 357.32 195.696C357.32 190.251 352.899 185.837 347.446 185.837C341.993 185.837 337.572 190.251 337.572 195.696C337.572 201.142 341.993 205.556 347.446 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 205.556C390.407 205.556 394.828 201.142 394.828 195.696C394.828 190.251 390.407 185.837 384.954 185.837C379.501 185.837 375.08 190.251 375.08 195.696C375.08 201.142 379.501 205.556 384.954 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 205.556C427.921 205.556 432.341 201.142 432.341 195.696C432.341 190.251 427.921 185.837 422.468 185.837C417.014 185.837 412.594 190.251 412.594 195.696C412.594 201.142 417.014 205.556 422.468 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 205.556C465.428 205.556 469.849 201.142 469.849 195.696C469.849 190.251 465.428 185.837 459.975 185.837C454.522 185.837 450.101 190.251 450.101 195.696C450.101 201.142 454.522 205.556 459.975 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 205.556C502.935 205.556 507.356 201.142 507.356 195.696C507.356 190.251 502.935 185.837 497.482 185.837C492.029 185.837 487.608 190.251 487.608 195.696C487.608 201.142 492.029 205.556 497.482 205.556Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 242.722C15.327 242.722 19.7477 238.308 19.7477 232.862C19.7477 227.417 15.327 223.003 9.87386 223.003C4.42068 223.003 0 227.417 0 232.862C0 238.308 4.42068 242.722 9.87386 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 242.722C52.8344 242.722 57.255 238.308 57.255 232.862C57.255 227.417 52.8344 223.003 47.3812 223.003C41.928 223.003 37.5073 227.417 37.5073 232.862C37.5073 238.308 41.928 242.722 47.3812 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 242.722C90.3417 242.722 94.7624 238.308 94.7624 232.862C94.7624 227.417 90.3417 223.003 84.8885 223.003C79.4353 223.003 75.0146 227.417 75.0146 232.862C75.0146 238.308 79.4353 242.722 84.8885 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 242.722C127.849 242.722 132.27 238.308 132.27 232.862C132.27 227.417 127.849 223.003 122.396 223.003C116.943 223.003 112.522 227.417 112.522 232.862C112.522 238.308 116.943 242.722 122.396 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 242.722C165.356 242.722 169.777 238.308 169.777 232.862C169.777 227.417 165.356 223.003 159.903 223.003C154.45 223.003 150.029 227.417 150.029 232.862C150.029 238.308 154.45 242.722 159.903 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 242.722C202.87 242.722 207.291 238.308 207.291 232.862C207.291 227.417 202.87 223.003 197.417 223.003C191.964 223.003 187.543 227.417 187.543 232.862C187.543 238.308 191.964 242.722 197.417 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 242.722C240.378 242.722 244.799 238.308 244.799 232.862C244.799 227.417 240.378 223.003 234.925 223.003C229.471 223.003 225.051 227.417 225.051 232.862C225.051 238.308 229.471 242.722 234.925 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 242.722C277.885 242.722 282.306 238.308 282.306 232.862C282.306 227.417 277.885 223.003 272.432 223.003C266.979 223.003 262.558 227.417 262.558 232.862C262.558 238.308 266.979 242.722 272.432 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 242.722C315.392 242.722 319.813 238.308 319.813 232.862C319.813 227.417 315.392 223.003 309.939 223.003C304.486 223.003 300.065 227.417 300.065 232.862C300.065 238.308 304.486 242.722 309.939 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 242.722C352.899 242.722 357.32 238.308 357.32 232.862C357.32 227.417 352.899 223.003 347.446 223.003C341.993 223.003 337.572 227.417 337.572 232.862C337.572 238.308 341.993 242.722 347.446 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 242.722C390.407 242.722 394.828 238.308 394.828 232.862C394.828 227.417 390.407 223.003 384.954 223.003C379.501 223.003 375.08 227.417 375.08 232.862C375.08 238.308 379.501 242.722 384.954 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 242.722C427.921 242.722 432.341 238.308 432.341 232.862C432.341 227.417 427.921 223.003 422.468 223.003C417.014 223.003 412.594 227.417 412.594 232.862C412.594 238.308 417.014 242.722 422.468 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 242.722C465.428 242.722 469.849 238.308 469.849 232.862C469.849 227.417 465.428 223.003 459.975 223.003C454.522 223.003 450.101 227.417 450.101 232.862C450.101 238.308 454.522 242.722 459.975 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 242.722C502.935 242.722 507.356 238.308 507.356 232.862C507.356 227.417 502.935 223.003 497.482 223.003C492.029 223.003 487.608 227.417 487.608 232.862C487.608 238.308 492.029 242.722 497.482 242.722Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 279.888C15.327 279.888 19.7477 275.474 19.7477 270.028C19.7477 264.583 15.327 260.169 9.87386 260.169C4.42068 260.169 0 264.583 0 270.028C0 275.474 4.42068 279.888 9.87386 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 279.888C52.8344 279.888 57.255 275.474 57.255 270.028C57.255 264.583 52.8344 260.169 47.3812 260.169C41.928 260.169 37.5073 264.583 37.5073 270.028C37.5073 275.474 41.928 279.888 47.3812 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 279.888C90.3417 279.888 94.7624 275.474 94.7624 270.028C94.7624 264.583 90.3417 260.169 84.8885 260.169C79.4353 260.169 75.0146 264.583 75.0146 270.028C75.0146 275.474 79.4353 279.888 84.8885 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 279.888C127.849 279.888 132.27 275.474 132.27 270.028C132.27 264.583 127.849 260.169 122.396 260.169C116.943 260.169 112.522 264.583 112.522 270.028C112.522 275.474 116.943 279.888 122.396 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 279.888C165.356 279.888 169.777 275.474 169.777 270.028C169.777 264.583 165.356 260.169 159.903 260.169C154.45 260.169 150.029 264.583 150.029 270.028C150.029 275.474 154.45 279.888 159.903 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 279.888C202.87 279.888 207.291 275.474 207.291 270.028C207.291 264.583 202.87 260.169 197.417 260.169C191.964 260.169 187.543 264.583 187.543 270.028C187.543 275.474 191.964 279.888 197.417 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 279.888C240.378 279.888 244.799 275.474 244.799 270.028C244.799 264.583 240.378 260.169 234.925 260.169C229.471 260.169 225.051 264.583 225.051 270.028C225.051 275.474 229.471 279.888 234.925 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 279.888C277.885 279.888 282.306 275.474 282.306 270.028C282.306 264.583 277.885 260.169 272.432 260.169C266.979 260.169 262.558 264.583 262.558 270.028C262.558 275.474 266.979 279.888 272.432 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 279.888C315.392 279.888 319.813 275.474 319.813 270.028C319.813 264.583 315.392 260.169 309.939 260.169C304.486 260.169 300.065 264.583 300.065 270.028C300.065 275.474 304.486 279.888 309.939 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 279.888C352.899 279.888 357.32 275.474 357.32 270.028C357.32 264.583 352.899 260.169 347.446 260.169C341.993 260.169 337.572 264.583 337.572 270.028C337.572 275.474 341.993 279.888 347.446 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 279.888C390.407 279.888 394.828 275.474 394.828 270.028C394.828 264.583 390.407 260.169 384.954 260.169C379.501 260.169 375.08 264.583 375.08 270.028C375.08 275.474 379.501 279.888 384.954 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 279.888C427.921 279.888 432.341 275.474 432.341 270.028C432.341 264.583 427.921 260.169 422.468 260.169C417.014 260.169 412.594 264.583 412.594 270.028C412.594 275.474 417.014 279.888 422.468 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 279.888C465.428 279.888 469.849 275.474 469.849 270.028C469.849 264.583 465.428 260.169 459.975 260.169C454.522 260.169 450.101 264.583 450.101 270.028C450.101 275.474 454.522 279.888 459.975 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 279.888C502.935 279.888 507.356 275.474 507.356 270.028C507.356 264.583 502.935 260.169 497.482 260.169C492.029 260.169 487.608 264.583 487.608 270.028C487.608 275.474 492.029 279.888 497.482 279.888Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 317.054C15.327 317.054 19.7477 312.64 19.7477 307.194C19.7477 301.749 15.327 297.335 9.87386 297.335C4.42068 297.335 0 301.749 0 307.194C0 312.64 4.42068 317.054 9.87386 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 317.054C52.8344 317.054 57.255 312.64 57.255 307.194C57.255 301.749 52.8344 297.335 47.3812 297.335C41.928 297.335 37.5073 301.749 37.5073 307.194C37.5073 312.64 41.928 317.054 47.3812 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 317.054C90.3417 317.054 94.7624 312.64 94.7624 307.194C94.7624 301.749 90.3417 297.335 84.8885 297.335C79.4353 297.335 75.0146 301.749 75.0146 307.194C75.0146 312.64 79.4353 317.054 84.8885 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 317.054C127.849 317.054 132.27 312.64 132.27 307.194C132.27 301.749 127.849 297.335 122.396 297.335C116.943 297.335 112.522 301.749 112.522 307.194C112.522 312.64 116.943 317.054 122.396 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 317.054C165.356 317.054 169.777 312.64 169.777 307.194C169.777 301.749 165.356 297.335 159.903 297.335C154.45 297.335 150.029 301.749 150.029 307.194C150.029 312.64 154.45 317.054 159.903 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 317.054C202.87 317.054 207.291 312.64 207.291 307.194C207.291 301.749 202.87 297.335 197.417 297.335C191.964 297.335 187.543 301.749 187.543 307.194C187.543 312.64 191.964 317.054 197.417 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 317.054C240.378 317.054 244.799 312.64 244.799 307.194C244.799 301.749 240.378 297.335 234.925 297.335C229.471 297.335 225.051 301.749 225.051 307.194C225.051 312.64 229.471 317.054 234.925 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 317.054C277.885 317.054 282.306 312.64 282.306 307.194C282.306 301.749 277.885 297.335 272.432 297.335C266.979 297.335 262.558 301.749 262.558 307.194C262.558 312.64 266.979 317.054 272.432 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 317.054C315.392 317.054 319.813 312.64 319.813 307.194C319.813 301.749 315.392 297.335 309.939 297.335C304.486 297.335 300.065 301.749 300.065 307.194C300.065 312.64 304.486 317.054 309.939 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 317.054C352.899 317.054 357.32 312.64 357.32 307.194C357.32 301.749 352.899 297.335 347.446 297.335C341.993 297.335 337.572 301.749 337.572 307.194C337.572 312.64 341.993 317.054 347.446 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 317.054C390.407 317.054 394.828 312.64 394.828 307.194C394.828 301.749 390.407 297.335 384.954 297.335C379.501 297.335 375.08 301.749 375.08 307.194C375.08 312.64 379.501 317.054 384.954 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 317.054C427.921 317.054 432.341 312.64 432.341 307.194C432.341 301.749 427.921 297.335 422.468 297.335C417.014 297.335 412.594 301.749 412.594 307.194C412.594 312.64 417.014 317.054 422.468 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 317.054C465.428 317.054 469.849 312.64 469.849 307.194C469.849 301.749 465.428 297.335 459.975 297.335C454.522 297.335 450.101 301.749 450.101 307.194C450.101 312.64 454.522 317.054 459.975 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 317.054C502.935 317.054 507.356 312.64 507.356 307.194C507.356 301.749 502.935 297.335 497.482 297.335C492.029 297.335 487.608 301.749 487.608 307.194C487.608 312.64 492.029 317.054 497.482 317.054Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 354.22C15.327 354.22 19.7477 349.806 19.7477 344.36C19.7477 338.915 15.327 334.501 9.87386 334.501C4.42068 334.501 0 338.915 0 344.36C0 349.806 4.42068 354.22 9.87386 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 354.22C52.8344 354.22 57.255 349.806 57.255 344.36C57.255 338.915 52.8344 334.501 47.3812 334.501C41.928 334.501 37.5073 338.915 37.5073 344.36C37.5073 349.806 41.928 354.22 47.3812 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 354.22C90.3417 354.22 94.7624 349.806 94.7624 344.36C94.7624 338.915 90.3417 334.501 84.8885 334.501C79.4353 334.501 75.0146 338.915 75.0146 344.36C75.0146 349.806 79.4353 354.22 84.8885 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 354.22C127.849 354.22 132.27 349.806 132.27 344.36C132.27 338.915 127.849 334.501 122.396 334.501C116.943 334.501 112.522 338.915 112.522 344.36C112.522 349.806 116.943 354.22 122.396 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 354.22C165.356 354.22 169.777 349.806 169.777 344.36C169.777 338.915 165.356 334.501 159.903 334.501C154.45 334.501 150.029 338.915 150.029 344.36C150.029 349.806 154.45 354.22 159.903 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 354.22C202.87 354.22 207.291 349.806 207.291 344.36C207.291 338.915 202.87 334.501 197.417 334.501C191.964 334.501 187.543 338.915 187.543 344.36C187.543 349.806 191.964 354.22 197.417 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 354.22C240.378 354.22 244.799 349.806 244.799 344.36C244.799 338.915 240.378 334.501 234.925 334.501C229.471 334.501 225.051 338.915 225.051 344.36C225.051 349.806 229.471 354.22 234.925 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 354.22C277.885 354.22 282.306 349.806 282.306 344.36C282.306 338.915 277.885 334.501 272.432 334.501C266.979 334.501 262.558 338.915 262.558 344.36C262.558 349.806 266.979 354.22 272.432 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 354.22C315.392 354.22 319.813 349.806 319.813 344.36C319.813 338.915 315.392 334.501 309.939 334.501C304.486 334.501 300.065 338.915 300.065 344.36C300.065 349.806 304.486 354.22 309.939 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 354.22C352.899 354.22 357.32 349.806 357.32 344.36C357.32 338.915 352.899 334.501 347.446 334.501C341.993 334.501 337.572 338.915 337.572 344.36C337.572 349.806 341.993 354.22 347.446 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 354.22C390.407 354.22 394.828 349.806 394.828 344.36C394.828 338.915 390.407 334.501 384.954 334.501C379.501 334.501 375.08 338.915 375.08 344.36C375.08 349.806 379.501 354.22 384.954 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 354.22C427.921 354.22 432.341 349.806 432.341 344.36C432.341 338.915 427.921 334.501 422.468 334.501C417.014 334.501 412.594 338.915 412.594 344.36C412.594 349.806 417.014 354.22 422.468 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 354.22C465.428 354.22 469.849 349.806 469.849 344.36C469.849 338.915 465.428 334.501 459.975 334.501C454.522 334.501 450.101 338.915 450.101 344.36C450.101 349.806 454.522 354.22 459.975 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 354.22C502.935 354.22 507.356 349.806 507.356 344.36C507.356 338.915 502.935 334.501 497.482 334.501C492.029 334.501 487.608 338.915 487.608 344.36C487.608 349.806 492.029 354.22 497.482 354.22Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 391.386C15.327 391.386 19.7477 386.972 19.7477 381.526C19.7477 376.081 15.327 371.667 9.87386 371.667C4.42068 371.667 0 376.081 0 381.526C0 386.972 4.42068 391.386 9.87386 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 391.386C52.8344 391.386 57.255 386.972 57.255 381.526C57.255 376.081 52.8344 371.667 47.3812 371.667C41.928 371.667 37.5073 376.081 37.5073 381.526C37.5073 386.972 41.928 391.386 47.3812 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 391.386C90.3417 391.386 94.7624 386.972 94.7624 381.526C94.7624 376.081 90.3417 371.667 84.8885 371.667C79.4353 371.667 75.0146 376.081 75.0146 381.526C75.0146 386.972 79.4353 391.386 84.8885 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 391.386C127.849 391.386 132.27 386.972 132.27 381.526C132.27 376.081 127.849 371.667 122.396 371.667C116.943 371.667 112.522 376.081 112.522 381.526C112.522 386.972 116.943 391.386 122.396 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 391.386C165.356 391.386 169.777 386.972 169.777 381.526C169.777 376.081 165.356 371.667 159.903 371.667C154.45 371.667 150.029 376.081 150.029 381.526C150.029 386.972 154.45 391.386 159.903 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 391.386C202.87 391.386 207.291 386.972 207.291 381.526C207.291 376.081 202.87 371.667 197.417 371.667C191.964 371.667 187.543 376.081 187.543 381.526C187.543 386.972 191.964 391.386 197.417 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 391.386C240.378 391.386 244.799 386.972 244.799 381.526C244.799 376.081 240.378 371.667 234.925 371.667C229.471 371.667 225.051 376.081 225.051 381.526C225.051 386.972 229.471 391.386 234.925 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 391.386C277.885 391.386 282.306 386.972 282.306 381.526C282.306 376.081 277.885 371.667 272.432 371.667C266.979 371.667 262.558 376.081 262.558 381.526C262.558 386.972 266.979 391.386 272.432 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 391.386C315.392 391.386 319.813 386.972 319.813 381.526C319.813 376.081 315.392 371.667 309.939 371.667C304.486 371.667 300.065 376.081 300.065 381.526C300.065 386.972 304.486 391.386 309.939 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 391.386C352.899 391.386 357.32 386.972 357.32 381.526C357.32 376.081 352.899 371.667 347.446 371.667C341.993 371.667 337.572 376.081 337.572 381.526C337.572 386.972 341.993 391.386 347.446 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 391.386C390.407 391.386 394.828 386.972 394.828 381.526C394.828 376.081 390.407 371.667 384.954 371.667C379.501 371.667 375.08 376.081 375.08 381.526C375.08 386.972 379.501 391.386 384.954 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 391.386C427.921 391.386 432.341 386.972 432.341 381.526C432.341 376.081 427.921 371.667 422.468 371.667C417.014 371.667 412.594 376.081 412.594 381.526C412.594 386.972 417.014 391.386 422.468 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 391.386C465.428 391.386 469.849 386.972 469.849 381.526C469.849 376.081 465.428 371.667 459.975 371.667C454.522 371.667 450.101 376.081 450.101 381.526C450.101 386.972 454.522 391.386 459.975 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 391.386C502.935 391.386 507.356 386.972 507.356 381.526C507.356 376.081 502.935 371.667 497.482 371.667C492.029 371.667 487.608 376.081 487.608 381.526C487.608 386.972 492.029 391.386 497.482 391.386Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 425.361C15.327 425.361 19.7477 420.947 19.7477 415.502C19.7477 410.056 15.327 405.642 9.87386 405.642C4.42068 405.642 0 410.056 0 415.502C0 420.947 4.42068 425.361 9.87386 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 425.361C52.8344 425.361 57.255 420.947 57.255 415.502C57.255 410.056 52.8344 405.642 47.3812 405.642C41.928 405.642 37.5073 410.056 37.5073 415.502C37.5073 420.947 41.928 425.361 47.3812 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 425.361C90.3417 425.361 94.7624 420.947 94.7624 415.502C94.7624 410.056 90.3417 405.642 84.8885 405.642C79.4353 405.642 75.0146 410.056 75.0146 415.502C75.0146 420.947 79.4353 425.361 84.8885 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 425.361C127.849 425.361 132.27 420.947 132.27 415.502C132.27 410.056 127.849 405.642 122.396 405.642C116.943 405.642 112.522 410.056 112.522 415.502C112.522 420.947 116.943 425.361 122.396 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 425.361C165.356 425.361 169.777 420.947 169.777 415.502C169.777 410.056 165.356 405.642 159.903 405.642C154.45 405.642 150.029 410.056 150.029 415.502C150.029 420.947 154.45 425.361 159.903 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 425.361C202.87 425.361 207.291 420.947 207.291 415.502C207.291 410.056 202.87 405.642 197.417 405.642C191.964 405.642 187.543 410.056 187.543 415.502C187.543 420.947 191.964 425.361 197.417 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 425.361C240.378 425.361 244.799 420.947 244.799 415.502C244.799 410.056 240.378 405.642 234.925 405.642C229.471 405.642 225.051 410.056 225.051 415.502C225.051 420.947 229.471 425.361 234.925 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 425.361C277.885 425.361 282.306 420.947 282.306 415.502C282.306 410.056 277.885 405.642 272.432 405.642C266.979 405.642 262.558 410.056 262.558 415.502C262.558 420.947 266.979 425.361 272.432 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 425.361C315.392 425.361 319.813 420.947 319.813 415.502C319.813 410.056 315.392 405.642 309.939 405.642C304.486 405.642 300.065 410.056 300.065 415.502C300.065 420.947 304.486 425.361 309.939 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 425.361C352.899 425.361 357.32 420.947 357.32 415.502C357.32 410.056 352.899 405.642 347.446 405.642C341.993 405.642 337.572 410.056 337.572 415.502C337.572 420.947 341.993 425.361 347.446 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 425.361C390.407 425.361 394.828 420.947 394.828 415.502C394.828 410.056 390.407 405.642 384.954 405.642C379.501 405.642 375.08 410.056 375.08 415.502C375.08 420.947 379.501 425.361 384.954 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 425.361C427.921 425.361 432.341 420.947 432.341 415.502C432.341 410.056 427.921 405.642 422.468 405.642C417.014 405.642 412.594 410.056 412.594 415.502C412.594 420.947 417.014 425.361 422.468 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 425.361C465.428 425.361 469.849 420.947 469.849 415.502C469.849 410.056 465.428 405.642 459.975 405.642C454.522 405.642 450.101 410.056 450.101 415.502C450.101 420.947 454.522 425.361 459.975 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 425.361C502.935 425.361 507.356 420.947 507.356 415.502C507.356 410.056 502.935 405.642 497.482 405.642C492.029 405.642 487.608 410.056 487.608 415.502C487.608 420.947 492.029 425.361 497.482 425.361Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 462.527C15.327 462.527 19.7477 458.113 19.7477 452.668C19.7477 447.222 15.327 442.808 9.87386 442.808C4.42068 442.808 0 447.222 0 452.668C0 458.113 4.42068 462.527 9.87386 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 462.527C52.8344 462.527 57.255 458.113 57.255 452.668C57.255 447.222 52.8344 442.808 47.3812 442.808C41.928 442.808 37.5073 447.222 37.5073 452.668C37.5073 458.113 41.928 462.527 47.3812 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 462.527C90.3417 462.527 94.7624 458.113 94.7624 452.668C94.7624 447.222 90.3417 442.808 84.8885 442.808C79.4353 442.808 75.0146 447.222 75.0146 452.668C75.0146 458.113 79.4353 462.527 84.8885 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 462.527C127.849 462.527 132.27 458.113 132.27 452.668C132.27 447.222 127.849 442.808 122.396 442.808C116.943 442.808 112.522 447.222 112.522 452.668C112.522 458.113 116.943 462.527 122.396 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 462.527C165.356 462.527 169.777 458.113 169.777 452.668C169.777 447.222 165.356 442.808 159.903 442.808C154.45 442.808 150.029 447.222 150.029 452.668C150.029 458.113 154.45 462.527 159.903 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 462.527C202.87 462.527 207.291 458.113 207.291 452.668C207.291 447.222 202.87 442.808 197.417 442.808C191.964 442.808 187.543 447.222 187.543 452.668C187.543 458.113 191.964 462.527 197.417 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 462.527C240.378 462.527 244.799 458.113 244.799 452.668C244.799 447.222 240.378 442.808 234.925 442.808C229.471 442.808 225.051 447.222 225.051 452.668C225.051 458.113 229.471 462.527 234.925 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 462.527C277.885 462.527 282.306 458.113 282.306 452.668C282.306 447.222 277.885 442.808 272.432 442.808C266.979 442.808 262.558 447.222 262.558 452.668C262.558 458.113 266.979 462.527 272.432 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 462.527C315.392 462.527 319.813 458.113 319.813 452.668C319.813 447.222 315.392 442.808 309.939 442.808C304.486 442.808 300.065 447.222 300.065 452.668C300.065 458.113 304.486 462.527 309.939 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 462.527C352.899 462.527 357.32 458.113 357.32 452.668C357.32 447.222 352.899 442.808 347.446 442.808C341.993 442.808 337.572 447.222 337.572 452.668C337.572 458.113 341.993 462.527 347.446 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 462.527C390.407 462.527 394.828 458.113 394.828 452.668C394.828 447.222 390.407 442.808 384.954 442.808C379.501 442.808 375.08 447.222 375.08 452.668C375.08 458.113 379.501 462.527 384.954 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 462.527C427.921 462.527 432.341 458.113 432.341 452.668C432.341 447.222 427.921 442.808 422.468 442.808C417.014 442.808 412.594 447.222 412.594 452.668C412.594 458.113 417.014 462.527 422.468 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 462.527C465.428 462.527 469.849 458.113 469.849 452.668C469.849 447.222 465.428 442.808 459.975 442.808C454.522 442.808 450.101 447.222 450.101 452.668C450.101 458.113 454.522 462.527 459.975 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 462.527C502.935 462.527 507.356 458.113 507.356 452.668C507.356 447.222 502.935 442.808 497.482 442.808C492.029 442.808 487.608 447.222 487.608 452.668C487.608 458.113 492.029 462.527 497.482 462.527Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 499.693C15.327 499.693 19.7477 495.279 19.7477 489.834C19.7477 484.388 15.327 479.974 9.87386 479.974C4.42068 479.974 0 484.388 0 489.834C0 495.279 4.42068 499.693 9.87386 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 499.693C52.8344 499.693 57.255 495.279 57.255 489.834C57.255 484.388 52.8344 479.974 47.3812 479.974C41.928 479.974 37.5073 484.388 37.5073 489.834C37.5073 495.279 41.928 499.693 47.3812 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 499.693C90.3417 499.693 94.7624 495.279 94.7624 489.834C94.7624 484.388 90.3417 479.974 84.8885 479.974C79.4353 479.974 75.0146 484.388 75.0146 489.834C75.0146 495.279 79.4353 499.693 84.8885 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 499.693C127.849 499.693 132.27 495.279 132.27 489.834C132.27 484.388 127.849 479.974 122.396 479.974C116.943 479.974 112.522 484.388 112.522 489.834C112.522 495.279 116.943 499.693 122.396 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 499.693C165.356 499.693 169.777 495.279 169.777 489.834C169.777 484.388 165.356 479.974 159.903 479.974C154.45 479.974 150.029 484.388 150.029 489.834C150.029 495.279 154.45 499.693 159.903 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 499.693C202.87 499.693 207.291 495.279 207.291 489.834C207.291 484.388 202.87 479.974 197.417 479.974C191.964 479.974 187.543 484.388 187.543 489.834C187.543 495.279 191.964 499.693 197.417 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 499.693C240.378 499.693 244.799 495.279 244.799 489.834C244.799 484.388 240.378 479.974 234.925 479.974C229.471 479.974 225.051 484.388 225.051 489.834C225.051 495.279 229.471 499.693 234.925 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 499.693C277.885 499.693 282.306 495.279 282.306 489.834C282.306 484.388 277.885 479.974 272.432 479.974C266.979 479.974 262.558 484.388 262.558 489.834C262.558 495.279 266.979 499.693 272.432 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 499.693C315.392 499.693 319.813 495.279 319.813 489.834C319.813 484.388 315.392 479.974 309.939 479.974C304.486 479.974 300.065 484.388 300.065 489.834C300.065 495.279 304.486 499.693 309.939 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 499.693C352.899 499.693 357.32 495.279 357.32 489.834C357.32 484.388 352.899 479.974 347.446 479.974C341.993 479.974 337.572 484.388 337.572 489.834C337.572 495.279 341.993 499.693 347.446 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 499.693C390.407 499.693 394.828 495.279 394.828 489.834C394.828 484.388 390.407 479.974 384.954 479.974C379.501 479.974 375.08 484.388 375.08 489.834C375.08 495.279 379.501 499.693 384.954 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 499.693C427.921 499.693 432.341 495.279 432.341 489.834C432.341 484.388 427.921 479.974 422.468 479.974C417.014 479.974 412.594 484.388 412.594 489.834C412.594 495.279 417.014 499.693 422.468 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 499.693C465.428 499.693 469.849 495.279 469.849 489.834C469.849 484.388 465.428 479.974 459.975 479.974C454.522 479.974 450.101 484.388 450.101 489.834C450.101 495.279 454.522 499.693 459.975 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 499.693C502.935 499.693 507.356 495.279 507.356 489.834C507.356 484.388 502.935 479.974 497.482 479.974C492.029 479.974 487.608 484.388 487.608 489.834C487.608 495.279 492.029 499.693 497.482 499.693Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 536.859C15.327 536.859 19.7477 532.445 19.7477 526.999C19.7477 521.554 15.327 517.14 9.87386 517.14C4.42068 517.14 0 521.554 0 526.999C0 532.445 4.42068 536.859 9.87386 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 536.859C52.8344 536.859 57.255 532.445 57.255 526.999C57.255 521.554 52.8344 517.14 47.3812 517.14C41.928 517.14 37.5073 521.554 37.5073 526.999C37.5073 532.445 41.928 536.859 47.3812 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 536.859C90.3417 536.859 94.7624 532.445 94.7624 526.999C94.7624 521.554 90.3417 517.14 84.8885 517.14C79.4353 517.14 75.0146 521.554 75.0146 526.999C75.0146 532.445 79.4353 536.859 84.8885 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 536.859C127.849 536.859 132.27 532.445 132.27 526.999C132.27 521.554 127.849 517.14 122.396 517.14C116.943 517.14 112.522 521.554 112.522 526.999C112.522 532.445 116.943 536.859 122.396 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 536.859C165.356 536.859 169.777 532.445 169.777 526.999C169.777 521.554 165.356 517.14 159.903 517.14C154.45 517.14 150.029 521.554 150.029 526.999C150.029 532.445 154.45 536.859 159.903 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 536.859C202.87 536.859 207.291 532.445 207.291 526.999C207.291 521.554 202.87 517.14 197.417 517.14C191.964 517.14 187.543 521.554 187.543 526.999C187.543 532.445 191.964 536.859 197.417 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 536.859C240.378 536.859 244.799 532.445 244.799 526.999C244.799 521.554 240.378 517.14 234.925 517.14C229.471 517.14 225.051 521.554 225.051 526.999C225.051 532.445 229.471 536.859 234.925 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 536.859C277.885 536.859 282.306 532.445 282.306 526.999C282.306 521.554 277.885 517.14 272.432 517.14C266.979 517.14 262.558 521.554 262.558 526.999C262.558 532.445 266.979 536.859 272.432 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 536.859C315.392 536.859 319.813 532.445 319.813 526.999C319.813 521.554 315.392 517.14 309.939 517.14C304.486 517.14 300.065 521.554 300.065 526.999C300.065 532.445 304.486 536.859 309.939 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 536.859C352.899 536.859 357.32 532.445 357.32 526.999C357.32 521.554 352.899 517.14 347.446 517.14C341.993 517.14 337.572 521.554 337.572 526.999C337.572 532.445 341.993 536.859 347.446 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 536.859C390.407 536.859 394.828 532.445 394.828 526.999C394.828 521.554 390.407 517.14 384.954 517.14C379.501 517.14 375.08 521.554 375.08 526.999C375.08 532.445 379.501 536.859 384.954 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 536.859C427.921 536.859 432.341 532.445 432.341 526.999C432.341 521.554 427.921 517.14 422.468 517.14C417.014 517.14 412.594 521.554 412.594 526.999C412.594 532.445 417.014 536.859 422.468 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 536.859C465.428 536.859 469.849 532.445 469.849 526.999C469.849 521.554 465.428 517.14 459.975 517.14C454.522 517.14 450.101 521.554 450.101 526.999C450.101 532.445 454.522 536.859 459.975 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 536.859C502.935 536.859 507.356 532.445 507.356 526.999C507.356 521.554 502.935 517.14 497.482 517.14C492.029 517.14 487.608 521.554 487.608 526.999C487.608 532.445 492.029 536.859 497.482 536.859Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 574.025C15.327 574.025 19.7477 569.611 19.7477 564.166C19.7477 558.72 15.327 554.306 9.87386 554.306C4.42068 554.306 0 558.72 0 564.166C0 569.611 4.42068 574.025 9.87386 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 574.025C52.8344 574.025 57.255 569.611 57.255 564.166C57.255 558.72 52.8344 554.306 47.3812 554.306C41.928 554.306 37.5073 558.72 37.5073 564.166C37.5073 569.611 41.928 574.025 47.3812 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 574.025C90.3417 574.025 94.7624 569.611 94.7624 564.166C94.7624 558.72 90.3417 554.306 84.8885 554.306C79.4353 554.306 75.0146 558.72 75.0146 564.166C75.0146 569.611 79.4353 574.025 84.8885 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 574.025C127.849 574.025 132.27 569.611 132.27 564.166C132.27 558.72 127.849 554.306 122.396 554.306C116.943 554.306 112.522 558.72 112.522 564.166C112.522 569.611 116.943 574.025 122.396 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 574.025C165.356 574.025 169.777 569.611 169.777 564.166C169.777 558.72 165.356 554.306 159.903 554.306C154.45 554.306 150.029 558.72 150.029 564.166C150.029 569.611 154.45 574.025 159.903 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 574.025C202.87 574.025 207.291 569.611 207.291 564.166C207.291 558.72 202.87 554.306 197.417 554.306C191.964 554.306 187.543 558.72 187.543 564.166C187.543 569.611 191.964 574.025 197.417 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 574.025C240.378 574.025 244.799 569.611 244.799 564.166C244.799 558.72 240.378 554.306 234.925 554.306C229.471 554.306 225.051 558.72 225.051 564.166C225.051 569.611 229.471 574.025 234.925 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 574.025C277.885 574.025 282.306 569.611 282.306 564.166C282.306 558.72 277.885 554.306 272.432 554.306C266.979 554.306 262.558 558.72 262.558 564.166C262.558 569.611 266.979 574.025 272.432 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 574.025C315.392 574.025 319.813 569.611 319.813 564.166C319.813 558.72 315.392 554.306 309.939 554.306C304.486 554.306 300.065 558.72 300.065 564.166C300.065 569.611 304.486 574.025 309.939 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 574.025C352.899 574.025 357.32 569.611 357.32 564.166C357.32 558.72 352.899 554.306 347.446 554.306C341.993 554.306 337.572 558.72 337.572 564.166C337.572 569.611 341.993 574.025 347.446 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 574.025C390.407 574.025 394.828 569.611 394.828 564.166C394.828 558.72 390.407 554.306 384.954 554.306C379.501 554.306 375.08 558.72 375.08 564.166C375.08 569.611 379.501 574.025 384.954 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 574.025C427.921 574.025 432.341 569.611 432.341 564.166C432.341 558.72 427.921 554.306 422.468 554.306C417.014 554.306 412.594 558.72 412.594 564.166C412.594 569.611 417.014 574.025 422.468 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 574.025C465.428 574.025 469.849 569.611 469.849 564.166C469.849 558.72 465.428 554.306 459.975 554.306C454.522 554.306 450.101 558.72 450.101 564.166C450.101 569.611 454.522 574.025 459.975 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 574.025C502.935 574.025 507.356 569.611 507.356 564.166C507.356 558.72 502.935 554.306 497.482 554.306C492.029 554.306 487.608 558.72 487.608 564.166C487.608 569.611 492.029 574.025 497.482 574.025Z"
        fill="#EDF0F2"
      />
      <path
        d="M9.87386 608C15.327 608 19.7477 603.586 19.7477 598.141C19.7477 592.695 15.327 588.281 9.87386 588.281C4.42068 588.281 0 592.695 0 598.141C0 603.586 4.42068 608 9.87386 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M47.3812 608C52.8344 608 57.255 603.586 57.255 598.141C57.255 592.695 52.8344 588.281 47.3812 588.281C41.928 588.281 37.5073 592.695 37.5073 598.141C37.5073 603.586 41.928 608 47.3812 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M84.8885 608C90.3417 608 94.7624 603.586 94.7624 598.141C94.7624 592.695 90.3417 588.281 84.8885 588.281C79.4353 588.281 75.0146 592.695 75.0146 598.141C75.0146 603.586 79.4353 608 84.8885 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.396 608C127.849 608 132.27 603.586 132.27 598.141C132.27 592.695 127.849 588.281 122.396 588.281C116.943 588.281 112.522 592.695 112.522 598.141C112.522 603.586 116.943 608 122.396 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M159.903 608C165.356 608 169.777 603.586 169.777 598.141C169.777 592.695 165.356 588.281 159.903 588.281C154.45 588.281 150.029 592.695 150.029 598.141C150.029 603.586 154.45 608 159.903 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M197.417 608C202.87 608 207.291 603.586 207.291 598.141C207.291 592.695 202.87 588.281 197.417 588.281C191.964 588.281 187.543 592.695 187.543 598.141C187.543 603.586 191.964 608 197.417 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M234.925 608C240.378 608 244.799 603.586 244.799 598.141C244.799 592.695 240.378 588.281 234.925 588.281C229.471 588.281 225.051 592.695 225.051 598.141C225.051 603.586 229.471 608 234.925 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M272.432 608C277.885 608 282.306 603.586 282.306 598.141C282.306 592.695 277.885 588.281 272.432 588.281C266.979 588.281 262.558 592.695 262.558 598.141C262.558 603.586 266.979 608 272.432 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M309.939 608C315.392 608 319.813 603.586 319.813 598.141C319.813 592.695 315.392 588.281 309.939 588.281C304.486 588.281 300.065 592.695 300.065 598.141C300.065 603.586 304.486 608 309.939 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M347.446 608C352.899 608 357.32 603.586 357.32 598.141C357.32 592.695 352.899 588.281 347.446 588.281C341.993 588.281 337.572 592.695 337.572 598.141C337.572 603.586 341.993 608 347.446 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M384.954 608C390.407 608 394.828 603.586 394.828 598.141C394.828 592.695 390.407 588.281 384.954 588.281C379.501 588.281 375.08 592.695 375.08 598.141C375.08 603.586 379.501 608 384.954 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M422.468 608C427.921 608 432.341 603.586 432.341 598.141C432.341 592.695 427.921 588.281 422.468 588.281C417.014 588.281 412.594 592.695 412.594 598.141C412.594 603.586 417.014 608 422.468 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M459.975 608C465.428 608 469.849 603.586 469.849 598.141C469.849 592.695 465.428 588.281 459.975 588.281C454.522 588.281 450.101 592.695 450.101 598.141C450.101 603.586 454.522 608 459.975 608Z"
        fill="#EDF0F2"
      />
      <path
        d="M497.482 608C502.935 608 507.356 603.586 507.356 598.141C507.356 592.695 502.935 588.281 497.482 588.281C492.029 588.281 487.608 592.695 487.608 598.141C487.608 603.586 492.029 608 497.482 608Z"
        fill="#EDF0F2"
      />
    </svg>
  );
};
