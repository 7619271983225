import { Dots } from "src/assets/svg/Dots";
import { Video } from "../Video";
import "./index.css";

const Hero = () => {
  const steps_work: string[] = [
    "trabalhar.",
    "produzir.",
    "crescer.",
    "interagir.",
    "colaborar.",
  ];

  const list: any[] = steps_work.map((item: string, i: number) => {
    return <div key={i}>{item}</div>;
  });

  return (
    <div className="hero content" id="hero">
      <div className="container-full">
        <h1>
          Reinventamos a forma de
          <div className="flip">{list}</div>
        </h1>
        <div className="info-video">
          <div className="info">
            <div className="intro">
              <h2>
                Captown é o seu espaço virtual para produzir de qualquer lugar!
              </h2>
              <p>
                Seja <span>virtual first</span> e tenha produtividade máxima
                independente do modelo de trabalho (remoto, presencial ou
                híbrido) adotado.
              </p>
            </div>
            <div className="intro-link">
              <h3>
                Junte-se as +20.000.000 de pessoas no modelo{" "}
                <span>virtual first</span> de trabalhar!
              </h3>
              <button>Comece já!</button>
            </div>
          </div>
          <div className="video-dots">
            <Dots />
            <Video />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
