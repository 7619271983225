export const LineConnect = () => {
  return (
    <div>
      <svg
        width="1440"
        height="444"
        viewBox="0 0 1440 444"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="logo-max"
      >
        <path
          d="M1439 0C1450.05 0 1459 8.9543 1459 20C1459 31.0457 1450.05 40 1439 40V0ZM1439 40H99V0H1439V40ZM99 58H368.5V98H99V58ZM368.5 157H-21.0284V117H368.5V157ZM-21.0284 174.943H623.972V214.943H-21.0284V174.943ZM623.972 273H421V233H623.972V273ZM369 325V443.957H329V325H369ZM421 273C392.281 273 369 296.281 369 325H329C329 274.19 370.19 233 421 233V273ZM673 223.972C673 251.049 651.049 273 623.972 273V233C628.958 233 633 228.958 633 223.972H673ZM623.972 174.943C651.049 174.943 673 196.894 673 223.972H633C633 218.985 628.958 214.943 623.972 214.943V174.943ZM-30 165.972C-30 170.926 -25.9833 174.943 -21.0284 174.943V214.943C-48.0747 214.943 -70 193.018 -70 165.972H-30ZM-21.0284 157C-25.9833 157 -30 161.017 -30 165.972H-70C-70 138.925 -48.0747 117 -21.0284 117V157ZM418 107.5C418 134.838 395.838 157 368.5 157V117C373.747 117 378 112.747 378 107.5H418ZM368.5 58C395.838 58 418 80.1619 418 107.5H378C378 102.253 373.747 98 368.5 98V58ZM90 49C90 53.9706 94.0294 58 99 58V98C71.938 98 50 76.062 50 49H90ZM99 40C94.0294 40 90 44.0295 90 49H50C50 21.938 71.9381 0 99 0V40Z"
          fill="url(#paint0_linear_427_4554)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_427_4554"
            x1="317.114"
            y1="-2.32348"
            x2="317.114"
            y2="402.688"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.192291" stopColor="#0AEA5A" />
            <stop offset="0.829867" stopColor="#A3FF24" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        width="414"
        height="420"
        viewBox="0 0 414 420"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="logo-min"
      >
        <path
          d="M725 0.000114441C736.046 0.000116348 745 8.95442 745 20.0001C745 31.0458 736.046 40.0001 725 40.0001L725 0.000114441ZM73 20V40V20ZM725 40.0001L73 40V5.72205e-06L725 0.000114441L725 40.0001ZM73 58H342.5V98H73V58ZM342.5 157H-47.0284V117H342.5V157ZM-47.0284 174.943H597.972V214.943H-47.0284V174.943ZM597.972 273H284V233H597.972V273ZM232 325V419.957H192V325H232ZM284 273C255.281 273 232 296.281 232 325H192C192 274.19 233.19 233 284 233V273ZM647 223.972C647 251.049 625.049 273 597.972 273V233C602.958 233 607 228.958 607 223.972H647ZM597.972 174.943C625.049 174.943 647 196.894 647 223.972H607C607 218.985 602.958 214.943 597.972 214.943V174.943ZM-56 165.972C-56 170.926 -51.9833 174.943 -47.0284 174.943V214.943C-74.0747 214.943 -96 193.018 -96 165.972H-56ZM-47.0284 157C-51.9833 157 -56 161.017 -56 165.972H-96C-96 138.925 -74.0747 117 -47.0284 117V157ZM392 107.5C392 134.838 369.838 157 342.5 157V117C347.747 117 352 112.747 352 107.5H392ZM342.5 58C369.838 58 392 80.1619 392 107.5H352C352 102.253 347.747 98 342.5 98V58ZM64 49C64 53.9706 68.0294 58 73 58V98C45.938 98 24 76.062 24 49H64ZM73 40C68.0294 40 64 44.0294 64 49H24C24 21.9381 45.938 0 73 5.72205e-06V40Z"
          fill="url(#paint0_linear_365_870)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_365_870"
            x1="291.113"
            y1="-2.32347"
            x2="291.113"
            y2="402.688"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.192291" stopColor="#0AEA5A" />
            <stop offset="0.829867" stopColor="#A3FF24" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
