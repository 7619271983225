import "./index.css";

import { Link } from "react-scroll";
import { ArrowLink } from "src/assets/svg/ArrowLink";

export const Nav = () => {
  const links = [
    { label: "No que acreditamos", link: "about" },
    { label: "Features", link: "pricing" },
    { label: "Benefícios", link: "feature" },

    { label: "Blog", link: "" },
  ];

  const list_links = links.map((item, i) => {
    return (
      <div className="link" key={i}>
        {item.label === "Blog" ? (
          <a
            className="flex items-center"
            href={item.link}
            target="_blank"
            rel="noreferrer"
          >
            {item.label}
            <ArrowLink class_svg="ml-2" />
          </a>
        ) : (
          <Link smooth={true} duration={500} to={item.link}>
            {item.label}
          </Link>
        )}
      </div>
    );
  });

  return <div className="nav">{list_links}</div>;
};
