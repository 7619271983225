import "./index.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import meet from "../../assets/img/meet.png";

export const Carousel = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const info_slider = [
    {
      title: "Conversas fluidas",
      body: "Tirar uma dúvida ou pedir uma opinião a um colega de trabalho ou projeto precisa ser natural como andar ao encontro pessoalmente. Entregamos isso! Inicie uma conversa caminhando até um colega e esqueça o processo obrigatório de enviar mensagem, verificar disponibilidade e envio de invite.",
      url: meet,
    },
    {
      title: "Muito além do bate-papo online",
      body: "A experiência em seu mundo virtual não está limitado a bate-papo em áudio e vídeo. Tenha salas privativas para receber convidados, realize apresentações, compartilhar tela, vídeos e documentos para entregar um melhor trabalho com a colaboração de todos",
      url: meet,
    },
    {
      title: "Seu espaço personalizado",
      body: "Assim como ter o seu próprio escritório no mundo real, o espaço virtual da Captown é totalmente personalizável. Escolha a planta seu escritório e personalize para refletir a sua cultura e visão. Fale conosco e podemos entregar um projeto exclusivo para sua empresa",
      url: meet,
    },
  ];

  const content = info_slider.map((info, i) => {
    return (
      <div className="content-slider" key={i}>
        <div className="text-slider">
          <h1>{info.title}</h1>
          <p>{info.body}</p>
        </div>
        <div className="img-slider">
          <img src={info.url} alt="" />
        </div>
      </div>
    );
  });

  return (
    <div className="carousel">
      <Slider {...settings}>{content}</Slider>
    </div>
  );
};
