import "./index.css";

import { List, Price } from "src/types/price";
import { Check } from "src/assets/svg/Check";

interface PriceProps {
  price: Price;
}

export const Card = ({ price }: PriceProps) => {
  const list: any[] = price.options.map((option: List, i: number) => {
    return (
      <li key={i}>
        <div>{option.label}</div>
        {option.check ? <Check /> : null}
      </li>
    );
  });

  return (
    <div className="card">
      <h1 className="title-card">{price.title}</h1>
      <div className="options">
        <ul>{list}</ul>
      </div>
      <div className="value">
        <p>
          {price.value}
          <span>/mês</span>
        </p>
      </div>
    </div>
  );
};
