import { Carousel } from "../Carousel";
import "./index.css";

export const Feature = () => {
  return (
    <div className="feature content" id="feature">
      <div className="container-post">
        <div className="post-it">Features</div>
      </div>
      <div className="container-full">
        <Carousel />
      </div>
    </div>
  );
};
