import "./index.css";

import { LineConnect } from "src/assets/svg/LineConnect";

export const Connect = () => {
  return (
    <div className="connect">
      <LineConnect />
    </div>
  );
};
